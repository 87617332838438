import { render, staticRenderFns } from "./notice.vue?vue&type=template&id=011b89c5&scoped=true"
import script from "./notice.vue?vue&type=script&lang=js"
export * from "./notice.vue?vue&type=script&lang=js"
import style0 from "./notice.vue?vue&type=style&index=0&id=011b89c5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "011b89c5",
  null
  
)

export default component.exports